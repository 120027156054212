import React, { Component } from 'react'
import Page from '../containers/page'
import { Container, Row, Col, Input } from 'reactstrap'
import Isvg from 'react-inlinesvg';
import { encodeBase64, saveAs } from '@progress/kendo-file-saver';

import FormBuilder from '../components/forms/formBuilder'
import { required } from '../components/forms/validation'
import Search from '../components/search'

import notification from '../assets/svg/notification.svg'
import profile from '../assets/svg/profile-image.svg'
import moreIcon from '../assets/svg/more.svg'
import rightIcon from '../assets/svg/arrow-right.svg'

import ErrorModal from '../components/errorModal';
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../helpers/linkHelper'
import { API_ENDPOINT } from '../constants'
import { Link } from 'react-router-dom';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import moment from 'moment';

import { Pie, Line } from 'react-chartjs-2';


class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            logsDate: [],
            loading: true,
            positions: [],
            startDay: moment.unix(Math.floor(new Date().setTime(new Date().getTime() - (6 * 24 * 60 * 60 * 1000)) / 1000)).format('YYYY-MM-DD'),
            endDay: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            types: [
                { name: 'Slika', value: 1 },
                { name: 'HTML', value: 2 }
            ]

        }
    }


    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
        this.getStats()
    }
    getStats = () => {
        let data = {
            startDay: this.state.startDay,
            endDay: this.state.endDay,
            position: this.state.selectedPosition
        }
        fetch(API_ENDPOINT + '/data/banners/stats/' + this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization:
                    typeof localStorage !== 'undefined'
                        ? `Bearer ${localStorage.getItem('authToken')}`
                        : null,
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result && result.length) {
                    let clicks = [];
                    let visits = [];
                    let logsDate = [];
                    for (let i = result.length - 1; i >= 0; i--) {
                        clicks.push(result[i].clicks);
                        visits.push(result[i].visits);
                        logsDate.push(result[i].date)
                    }
                    this.setState({ logsStats: result, clicks, logsDate, visits })
                }


            })

    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }


        this.get()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
        if (prevProps.type != this.props.type) {
            if (this.props.type == 'None') {
                this.props.changeType(null)
            }
        }
        if (prevProps.position != this.props.position) {
            if (this.props.position == 'None') {
                this.props.changePosition(null)
            }
        }

    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    insertOrUpdate = (data) => {

        this.setState(
            {
                loading: true,
            },
            () => {
                if (!this.props[0].match.params.id) {
                    fetch(API_ENDPOINT + '/data/banners/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.props[0].history.push('/banners')
                            }

                        })
                } else {
                    fetch(API_ENDPOINT + '/data/banners/' + this.props[0].match.params.id, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.props[0].history.push('/banners')
                            }

                        })
                }
            }
        )
    }


    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        const data = {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
        // Chart.defaults.scale.gridLines.drawOnChartArea = false;
        // Chart.defaults.global.legend.display = false;
        const data1 = {
            labels: this.state.logsDate,

            datasets: [
                {
                    label: "Broj klikova",
                    data: this.state.clicks,
                    fill: true,
                    backgroundColor: "rgba(47, 98, 156, 0.5)",
                    borderColor: "rgba(47, 98, 156, 1)"
                },
                {
                    label: "Broj prikaza",
                    data: this.state.visits,
                    fill: true,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderColor: "rgba(255, 99, 132, 0.2)"
                },
            ],


        };



        return (

            <div className='page'>
                <Container className={this.props.acitveSidebar ? 'dashboard' : 'dashboard padd-change'}>
                    {console.log(this.state.initialValues)}
                    <Row>
                        <Col lg="12">
                            <FormBuilder

                                onSubmit={(data) => this.insertOrUpdate(data)}
                                initialValues={this.state.initialValues}

                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12
                                                },
                                                children: [{
                                                    type: 'div',
                                                    className: 'main-form',
                                                    children: [{
                                                        type: 'row',
                                                        children: [
                                                            //HEADER
                                                            {//naslov forme
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'div',
                                                                        className: 'form-header',
                                                                        children: [
                                                                            {
                                                                                type: 'h5',
                                                                                className: 'component-header',
                                                                                text: 'Dodavanje banera'
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            },

                                                            //   {
                                                            //     type: 'col',
                                                            //     width: {
                                                            //       lg: 10, sm: 12, xs: 12
                                                            //     },
                                                            //     children: [
                                                            //       {
                                                            //         type: 'div',
                                                            //         className: 'form-header-buttons',
                                                            //         children: [
                                                            //           {
                                                            //             type: 'div',
                                                            //             className: 'form-title-buttons',
                                                            //             children: [
                                                            //               {
                                                            //                 type: 'switch',
                                                            //                 label: 'Aktivna',
                                                            //                 name: 'active',

                                                            //               },
                                                            //               {
                                                            //                 type: 'switch',
                                                            //                 label: 'Video',
                                                            //                 name: 'video'
                                                            //               },
                                                            //               {
                                                            //                 type: 'switch',
                                                            //                 label: 'Izdvojena',
                                                            //                 name: 'segregate'
                                                            //               },
                                                            //               {
                                                            //                 type: 'switch',
                                                            //                 label: 'Udarna',
                                                            //                 name: 'breaking'
                                                            //               },
                                                            //               {
                                                            //                 type: 'button',
                                                            //                 className: 'main-form-button button-2',
                                                            //                 text: <a href={API_ENDPOINT+'/vijesti/'+(this.state.initialValues ? this.state.initialValues.alias : null)} target="_blank">Pregledaj vijest{' '}
                                                            //                   <span>
                                                            //                     {' '}
                                                            //                     <Isvg src={rightIcon} />{' '}
                                                            //                   </span>{' '} </a>,
                                                            //                 name: 'alias',
                                                            //               }

                                                            //             ]
                                                            //           }
                                                            //         ]
                                                            //       }
                                                            //     ]
                                                            //   },
                                                            //END HEADER
                                                            //FORMA za unos vijesi
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'title',
                                                                        label: 'Naziv banera',
                                                                        validate: [required('Naziv je obavezan!')]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'position',
                                                                        multiple: false,
                                                                        values: [{ name: 'None', value: null }, ...this.state.positions.map(item => {
                                                                            return {
                                                                                name: item.title,
                                                                                value: item._id,
                                                                            }
                                                                        })],
                                                                        label: 'Pozicija',
                                                                        validate: [required('Pozicija je obavezna!')],
                                                                    },
                                                                ]
                                                            },


                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'type',
                                                                        multiple: false,
                                                                        values: [{ name: 'None', value: null }, ...this.state.types.map(item => {
                                                                            return {
                                                                                name: item.name,
                                                                                value: item.value
                                                                            }
                                                                        })],
                                                                        label: 'Tip',
                                                                        validate: [required('Tip je obavezna!')],
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    this.props.type == 2 ?

                                                                        {
                                                                            type: 'textarea',
                                                                            name: 'adsense',
                                                                            label: 'HTML',
                                                                            height: 300,
                                                                            validate: [required('HTML je obavezan!')]
                                                                        }
                                                                        :

                                                                        {
                                                                            type: 'empty',
                                                                        },
                                                                    this.props.type == 1 ?

                                                                        {
                                                                            type: 'image',
                                                                            name: 'image',
                                                                            label: 'Slika',
                                                                            height: 300,
                                                                            // validate: [required('Slika je obavezan!')]
                                                                        }
                                                                        :

                                                                        {
                                                                            type: 'empty',
                                                                        },
                                                                    this.props.type == 1 ?

                                                                        {
                                                                            type: 'text',
                                                                            name: 'link',
                                                                            label: 'Link',
                                                                        }
                                                                        :

                                                                        {
                                                                            type: 'empty',
                                                                        }


                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'limit',
                                                                        label: 'Limit',
                                                                    },
                                                                ]
                                                            },




                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 12, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'submit-button',
                                                                        className: 'main-form-button button-1',
                                                                        text: this.props[0].match.params.id ? 'Izmjeni baner' : 'Dodaj baner',
                                                                    },

                                                                ]
                                                            },
                                                        ]
                                                    }]
                                                }
                                                ]
                                            },

                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                            <Col lg='9'>
                                {
                                    this.state.clicks ?

                                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: 50 }}>

                                            <div className="start-end-picker-wrap">
                                                <Input type="date" value={this.state.startDay} onChange={(e) => {
                                                    let selectedDay = Math.floor(new Date(e.target.value).getTime() / 1000);
                                                    let endDay = Math.floor(new Date(this.state.endDay).getTime() / 1000);
                                                    if ((endDay - selectedDay) >= 0) {
                                                        this.setState({ startDay: e.target.value }, () => this.getStats())
                                                    } else {
                                                        this.setState({ startDay: this.state.endDay }, () => this.getStats())
                                                    }

                                                }} />
                                                <Input type="date" value={this.state.endDay} onChange={(e) => {
                                                    let selectedDay = Math.floor(new Date(e.target.value).getTime() / 1000);
                                                    let startDay = Math.floor(new Date(this.state.startDay).getTime() / 1000);
                                                    if ((selectedDay - startDay) >= 0) {
                                                        this.setState({ endDay: e.target.value }, () => this.getStats())
                                                    } else {
                                                        this.setState({ endDay: this.state.startDay }, () => this.getStats())
                                                    }

                                                }} />

                                            </div>
                                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 50 }}>

                                                {/* <div style={{ width: '50%' }}> */}
                                                <Line data={data1} />
                                                {/* </div> */}
                                            </div>
                                        </div>

                                        :
                                        null
                                }
                            </Col>

                            {this.state.error ?

                                <ErrorModal
                                    isOpen={this.state.error}
                                    toggle={() => this.setState({ error: null })}

                                >
                                    {this.state.error.translate(this.props.lang)}
                                </ErrorModal>

                                :
                                null
                            }
                        </Col>

                    </Row>
                </Container>
            </div >
        )
    }
}
const selector = formValueSelector('form');

export default connect(state => {
    return {
        type: selector(state, 'type'),
        position: selector(state, 'position'),
    }

}, {
    changeType: value => change("form", "type", value),
    changePosition: value => change("form", "position", value),

})(Page(Form));