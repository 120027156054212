import React, { Component } from 'react'

import Isvg from 'react-inlinesvg'
import Page from '../containers/page'

import { Container, Row, Col } from 'reactstrap'

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper'

import Search from '../components/search'

import notification from '../assets/svg/notification.svg'
import profile from '../assets/svg/profile-image.svg'
import moreIcon from '../assets/svg/more.svg'
import map from '../assets/svg/map.svg'
import chart from '../assets/svg/chart.svg';
import newsIcon from '../assets/svg/counter-news-icon.svg';
import commentIcon from '../assets/svg/counter-comment-icon.svg';
import stuffIcon from '../assets/svg/counter-stuff-icon.svg';
import arrowRight from '../assets/svg/arrow-right.svg'
import SwitchButton from '../components/forms/fields/toggle.js'

var striptags = require('striptags')

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      promotedProjects: [],
      ...props.initialData,
      form: false,

      useState: false,
      bannerForm: false,
      params: {},
      areas: [],
      showStatistics: false,
    }
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }
  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  handleOptionChange(event){
    this.setState({value: event.target.value});
  }


  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    this.get()

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  toggle = () => {
    this.setState({
      form: !this.state.form,
    })
  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          {},
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          {},
          fields,
          restart
        )
      )
    }
  }

  render() {
    let params = getSearchParams(this.props[0].location.search, {
      entries: 10,
      page: 0,
    })
    console.log(params)
    return (
    
      <div className='page'>
        
        <Container className={this.props.acitveSidebar ? 'dashboard' : 'dashboard padd-change'}>
          <Row>
            <Col lg='9' sm='12' xs='12'>
              <div className='component filter-content'>
                <form>
                  <h5 className='component-header'>Pronađi željenu vijest</h5>
                  <div className='form-group'>
                    <input placeholder='Pretraži bazu...' className='form-control' />
                  </div>
                  <div className='form-group'>
                    <select name='options'>
                      <option value='421412'>Izaberite kategoriju</option>
                      <option value='421'>Izaberite kategoriju</option>
                    </select>
                    <select name='options'>
                      <option>Izaberite datum</option>
                    </select>
                    <button type='submit'>
                      Pretraži
              <Isvg src={arrowRight} />
                    </button>
                  </div>
                </form>
              </div>
              
              {/* <div className="counter-blocks">
                <div className="counter-block">
                  <div className="counter-block-content">
                    <h5>Ukupno vijesti</h5>
                    <h3>{this.state.totalNumberOfNews}</h3>
                  </div>
                  <div className="counter-block-icon">
                    <Isvg src={newsIcon} />
                  </div>
                </div>

                <div className="counter-block">
                  <div className="counter-block-content">
                    <h5>Ukupno komentara</h5>
                    <h3>{this.state.totalNumberOfComments}</h3>
                  </div>
                  <div className="counter-block-icon">
                    <Isvg src={commentIcon} />
                  </div>
                </div>

                <div className="counter-block">
                  <div className="counter-block-content">
                    <h5>Ukupno korisnika</h5>
                    <h3>{this.state.totalNumberOfAdmins}</h3>
                  </div>
                  <div className="counter-block-icon">
                    <Isvg src={stuffIcon} />
                  </div>
                </div>
              </div> */}
              {/* {this.props.uData.role == 'Administrator' ?
              <Row>
                <div className='component popular-pages'>
                  <h5 className='component-header'>Najgledanije stranice</h5>
                  {this.state.mostViewed && this.state.mostViewed.map((item,idx)=>{
                   return(
                    <div><span className='page-name'>{item.title}</span>
                    <div>
                      {item.views} pregleda
                    </div><hr></hr></div>
                   )
                  })}
                </div>

              </Row>
                : ''} */}
            </Col>
            <Col lg='3' sm='12' xs='12'>
              {this.props.uData.role == 'Administrator' ?
              <div className='component customer-age'>
                <h5 className='component-header'>Statistike za korisnika</h5>
                <select value={this.state.value} onChange={this.handleOptionChange} name='options'>
                  {this.state.users && this.state.users.map((item,idx)=>{
                    return(
                      <option value={idx}>{item.username}</option>
                    )
                  })}
                </select>    
                {this.state.value ? <div>Ukupno vijesti: {this.state.users[this.state.value].newsCount}<br></br>
                <br></br>
                Ukupno vijesti u prethodnih mjesec dana: {this.state.users[this.state.value].newsCountm}<br></br>
                <br></br>
                Ukupno vijesti u poslednja 24h: {this.state.users[this.state.value].newsCount24}<br></br>
                <br></br>
                Ukupno vijesti u prethodnih 7 dana: {this.state.users[this.state.value].newsCount7d}<br></br>
                </div>:''}
              </div>
              :""}
              

            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page(HomePage)
