import React, { Component, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import Isvg from 'react-inlinesvg'

import moment from 'moment'

import logo from '../assets/svg/logo-sidebar.svg'
import menuIcon from '../assets/svg/icon-menu.svg'
import homeIcon from '../assets/svg/home-icon.svg'
import commentIcon from '../assets/svg/comments-icon.svg'
import administratorIcon from '../assets/svg/administrator-icon.svg'
import moderatorIcon from '../assets/svg/moderator-icon.svg'
import editorIcon from '../assets/svg/editor-icon.svg'
import logoutIcon from '../assets/svg/logout-icon.svg'
import logoBottom from '../assets/svg/logo-bottom.svg'
import arrowDown from '../assets/svg/arrow-down.svg'
import addIcon from '../assets/svg/listnews-icon.svg'
import listIcon from '../assets/svg/addnews-icon.svg'

moment.locale('sr')

import { API_ENDPOINT } from '../constants'

export class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      showNews: false,
      showCategories:false,
      showPages:false,
      showAdministrator: false,
      newsActive: false,
      bannersActive: false,
      categoriesActive: false,
      sortCategoriesActive:false,
      administratorActive: false,
      highlightPage: false,
      homePage: false,
      commentPage: false,
      pagesActive:false,
    }
    console.log(this.props[0].location.pathname);
    if(this.props[0].location.pathname == '/'){
      this.state.homePage = true;
    }

    if(this.props[0].location.pathname == '/comments'){
      this.state.commentPage = true;
    }

    if((this.props[0].location.pathname == '/category' || this.props[0].location.pathname == '/addCategory') || this.props[0].location.pathname == '/sortCategory'){
      this.state.categoriesActive = true;
      this.state.sortCategoriesActive=true;
      this.state.showCategories = true;
    }
    if(this.props[0].location.pathname == '/news' || this.props[0].location.pathname == '/addNews'){
      this.state.newsActive = true;
      this.state.showNews = true;
    }
    if(this.props[0].location.pathname == '/banners' || this.props[0].location.pathname == '/addBanner' || this.props[0].location.pathname == '/positions'  || this.props[0].location.pathname == '/addPosition'){
      this.state.bannersActive = true;
      this.state.showBanners = true;
    }
    if(this.props[0].location.pathname == '/admin' || this.props[0].location.pathname == '/addUser'){
      this.state.administratorActive = true;
      this.state.showAdministrator = true;
    }
    if(this.props[0].location.pathname == '/pages' || this.props[0].location.pathname == '/addPages'){
      this.state.pagesActive = true;
      this.state.showPages = true;
    }

  }

  toggleSidebar = () => {
    const currentState = this.state.active
    this.setState({ active: !currentState })
    this.props.setActiveSidebar(currentState)
  }
  toggleNewsDropdown = () => {
    const currentState = this.state.showNews
    this.setState({ showNews: !currentState })
  }
  
  toggleBannersDropdown = () => {
    const currentState = this.state.showBanners
    this.setState({ showBanners: !currentState })
  }
  toggleCategoriesDropdown = () => {
    const currentState = this.state.showCategories
    this.setState({ showCategories: !currentState })
  }
  togglePagesDropdown = () => {
    const currentState = this.state.showPages
    this.setState({ showPages: !currentState })
  }
  toggleAdministratorDropdown = () => {
    const currentState = this.state.showAdministrator
    this.setState({ showAdministrator: !currentState })
  }
  toggleNewsLink = () => {
    const currentState = this.state.newsActive
    this.setState({ newsActive: !currentState })
  }
  toggleBannersLink = () => {
    const currentState = this.state.bannersActive
    this.setState({ bannersActive: !currentState })
  }
  toggleCategoriesLink = () => {
    const currentState = this.state.categoriesActive
    this.setState({ categoriesActive: !currentState })
  }
  toggleAdministratorLink = () => {
    const currentState = this.state.administratorActive
    this.setState({ administratorActive: !currentState })
  }
  togglePagesLink = () => {
    const currentState = this.state.pagesActive
    this.setState({ pagesActive: !currentState })
  }

  componentDidMount(){
    this.get();
  }
  get = () => {
    this.props.getNumberOfComments();
  }

  render() {
    if (!this.props.uData) {
      return null
    }
    return (
      <header>
        <div className={this.state.active ? 'sidebar active' : 'sidebar'}>
          <div classNames='sidebar-content'>
            <div className='sidebar-header'>
              <a target="_blank" href={API_ENDPOINT}><Isvg src={logo} className='logo-image' /></a>
              <Isvg
                src={menuIcon}
                className='menu-icon'
                onClick={this.toggleSidebar}
              />
            </div>
            <div className='sidebar-body'>
              <ul className='menu'>
                <li className={this.state.homePage ? 'menu__item item-active': 'menu__item'}>
                  <Link to='/' className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={homeIcon} />{' '}
                    </span>
                    <span className='menu__text'>Home</span>
                  </Link>
                </li>
                <li className={this.state.newsActive ? 'menu__item item-active' : 'menu__item'} onClick={this.toggleNewsLink}>
                  <a className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={homeIcon} />{' '}
                    </span>
                    <span className='menu__text'
                    onClick={this.toggleNewsDropdown}
                    > Vijesti </span>
                  </a>
                  <Isvg
                    src={arrowDown}
                    id='dropdown-icon'
                    onClick={this.toggleNewsDropdown}
                  />
                  <ul
                    className={this.state.showNews ? 'dropdown show-news' : 'dropdown'}
                  >
                    <li
                      className={
                        this.props[0].location.pathname == '/news'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/newsWithQuery'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Lista vijesti</span>
                      </Link>
                    </li>

                    <li
                      className={
                        this.props[0].location.pathname == '/addNews'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/addNews'>
                        <span>
                          {' '}
                          <Isvg src={addIcon} />{' '}
                        </span>
                        <span className='menu__text'>Dodaj vijest</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className={this.state.bannersActive ? 'menu__item item-active' : 'menu__item'} onClick={this.toggleBannersLink}>
                  <a className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={homeIcon} />{' '}
                    </span>
                    <span className='menu__text'
                    onClick={this.toggleBannersDropdown}
                    > Baneri </span>
                  </a>
                  <Isvg
                    src={arrowDown}
                    id='dropdown-icon'
                    onClick={this.toggleBannersDropdown}
                  />
                  <ul
                    className={this.state.showBanners ? 'dropdown show-news' : 'dropdown'}
                  >
                    <li
                      className={
                        this.props[0].location.pathname == '/banners'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/banners'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Lista banera</span>
                      </Link>
                    </li>

                    <li
                      className={
                        this.props[0].location.pathname == '/addBanner'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/addBanner'>
                        <span>
                          {' '}
                          <Isvg src={addIcon} />{' '}
                        </span>
                        <span className='menu__text'>Dodaj baner</span>
                      </Link>
                    </li>

                    <li
                      className={
                        this.props[0].location.pathname == '/positions'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/positions'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Lista pozicija</span>
                      </Link>
                    </li>

                    <li
                      className={
                        this.props[0].location.pathname == '/addPosition'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/addPosition'>
                        <span>
                          {' '}
                          <Isvg src={addIcon} />{' '}
                        </span>
                        <span className='menu__text'>Dodaj poziciju</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                {this.props.uData.role == 'Administrator' ?
                <li className={this.state.categoriesActive ? 'menu__item item-active' : 'menu__item'} onClick={this.toggleCategoriesLink}>
                  <a className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={homeIcon} />{' '}
                    </span>
                    <span className='menu__text'
                    onClick={this.toggleCategoriesDropdown}
                    > Kategorije </span>
                  </a>
                  <Isvg
                    src={arrowDown}
                    id='dropdown-icon'
                    onClick={this.toggleCategoriesDropdown}
                  />
                  <ul
                    className={this.state.showCategories ? 'dropdown show-news' : 'dropdown'}
                  >
                    <li
                      className={
                        this.props[0].location.pathname == '/category'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/category'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Lista kategorija</span>
                      </Link>
                    </li>
                    <li
                      className={
                        this.props[0].location.pathname == '/addCategory'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/addCategory'>
                        <span>
                          {' '}
                          <Isvg src={addIcon} />{' '}
                        </span>
                        <span className='menu__text'> Dodaj kategoriju</span>
                      </Link>
                    </li>
                    <li
                      className={
                        this.props[0].location.pathname == '/sortCategory'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/sortCategory'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Sortiraj kategorije</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                :''}
                {this.props.uData.role == 'Administrator' ?
                <li className={this.state.pagesActive ? 'menu__item item-active' : 'menu__item'} onClick={this.togglePagesLink}>
                  <a className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={homeIcon} />{' '}
                    </span>
                    <span className='menu__text'
                    onClick={this.togglePagesDropdown}
                    > Stranice </span>
                  </a>
                  <Isvg
                    src={arrowDown}
                    id='dropdown-icon'
                    onClick={this.togglePagesDropdown}
                  />
                  <ul
                    className={this.state.showPages ? 'dropdown show-news' : 'dropdown'}
                  >
                    <li
                      className={
                        this.props[0].location.pathname == '/pages'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/pages'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Lista stranica</span>
                      </Link>
                    </li>
                    <li
                      className={
                        this.props[0].location.pathname == '/addPages'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/addPages'>
                        <span>
                          {' '}
                          <Isvg src={addIcon} />{' '}
                        </span>
                        <span className='menu__text'>Nova stranica</span>
                      </Link>
                    </li>

                  </ul>
                </li>
                :''}
                {this.props.uData.role == 'Administrator' || this.props.uData.role =="Moderator" ?
                <li className={this.state.commentPage ? 'menu__item item-active': 'menu__item'}>
                  <a href="/comments" className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={commentIcon} />{' '}
                    </span>
                    <span className='menu__text'> Komentari  ({this.props.numOfUnreadComments})</span>
                  </a>
                </li>
                :""}
                {this.props.uData.role == 'Administrator' ? 
                <li className={this.state.administratorActive ? 'menu__item item-active' : 'menu__item'} onClick={this.toggleAdministratorLink}>
                  <a className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={administratorIcon} />{' '}
                    </span>
                    <span className='menu__text'
                    onClick={this.toggleAdministratorDropdown}
                    > Administratori </span>
                  </a>
                  <Isvg
                    src={arrowDown}
                    id='dropdown-icon'
                    onClick={this.toggleAdministratorDropdown}
                  />
                  <ul
                    className={this.state.showAdministrator ? 'dropdown show-administrator' : 'dropdown'}
                  >
                    <li
                      className={
                        this.props[0].location.pathname == '/admin'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/admin'>
                        <span>
                          {' '}
                          <Isvg src={listIcon} />{' '}
                        </span>
                        <span className='menu__text'>Lista korisnika</span>
                      </Link>
                    </li>
                    <li
                      className={
                        this.props[0].location.pathname == '/addUser'
                          ? 'active-highlighted'
                          : ''
                      }
                    >
                      <Link to='/addUser'>
                        <span>
                          {' '}
                          <Isvg src={addIcon} />{' '}
                        </span>
                        <span className='menu__text'> Dodaj korisnika</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                : null }
                    
                <li className='menu__item'>
                  <a className='menu__link'>
                    <span >
                      {' '}
                      <Isvg src={logoutIcon} />{' '}
                    </span>
                    <span className='menu__text' onClick={this.props.signOut}> Logout </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='sidebar-footer'>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
